import React from 'react';
import Press from 'src/modules/home/visitor/components/press';
import config from 'config/config';
const PressList = () => {
  const presslist = [{
    id: 'kingsfund',
    pressPath: `${config.cdn.static}img/home/logo-kingsfund.jpg`,
    pressUrl: 'https://www.kingsfund.org.uk/publications/shared-responsibility-health'
  }, {
    id: 'nejm-catalyst',
    pressPath: `${config.cdn.static}img/home/logo-nejm-catalyst.svg`,
    pressUrl: 'https://catalyst.nejm.org/superusers-allies-online-health-communities/'
  }, {
    id: 'econsultancy',
    pressPath: `${config.cdn.static}img/home/logo-econsultancy.png`,
    pressUrl: 'https://econsultancy.com/three-trends-driving-digital-within-healthcare/'
  }, {
    id: 'forbes',
    pressPath: `${config.cdn.static}img/home/logo-forbes.png`,
    pressUrl: 'https://www.forbes.com/sites/onmarketing/2018/01/24/five-trends-for-marketers-to-watch-in-2018/#6893586751c0'
  }, {
    id: 'eveningstandard',
    pressPath: `${config.cdn.static}img/home/logo-eveningstandard.png`,
    pressUrl: 'https://www.standard.co.uk/tech/best-apps-for-mental-health-world-mental-health-day-a3958201.html'
  }, {
    id: 'huffpost',
    pressPath: `${config.cdn.static}img/home/logo-huffpost.png`,
    pressUrl: 'https://www.huffingtonpost.com/fueled/londons-7-fast-growing--d_b_5845206.html'
  }];
  return <div className="home__presslist" data-sentry-component="PressList" data-sentry-source-file="pressList.tsx">
      {presslist.map(press => <Press id={press.id} key={press.id} pressPath={press.pressPath} pressUrl={press.pressUrl} />)}
    </div>;
};
export default PressList;