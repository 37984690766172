// @ts-strict-ignore
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
const Press = ({
  pressPath,
  id,
  pressUrl
}) => <div className="home__press" data-sentry-component="Press" data-sentry-source-file="press.tsx">
    <a href={pressUrl} rel="noreferrer" target="_blank" onClick={() => {
    trackFootfallEvent(Event.Clicked, {
      clickMetadata: {
        section: Section.PressLinks
      },
      clickType: 'visitor-homepage'
    });
  }}>
      <img alt={id} className="home__press__image" src={pressPath} />
    </a>
  </div>;
export default Press;