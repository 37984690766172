import Image from 'next/image';
import styled from 'styled-components';
import JoinForm from 'src/modules/home/visitor/components/joinForm';
import i18n from 'src/utils/translate';
import config from 'config/config';
import { titleLarge } from 'styles-js/mixins/typography';
export default function JoinSection() {
  return <Section data-sentry-element="Section" data-sentry-component="JoinSection" data-sentry-source-file="joinSection.tsx">
      <Image alt="Person looking at their phone" fill={true} src={`${config.cdn.static}img/home/homepage4-2098x1400.jpg`} style={{
      objectFit: 'cover'
    }} data-sentry-element="Image" data-sentry-source-file="joinSection.tsx" />
      <Overlay data-sentry-element="Overlay" data-sentry-source-file="joinSection.tsx">
        <Content data-sentry-element="Content" data-sentry-source-file="joinSection.tsx">
          <h2>{i18n.t('Join today. For free, forever.')}</h2>
          <JoinForm data-sentry-element="JoinForm" data-sentry-source-file="joinSection.tsx" />
        </Content>
      </Overlay>
    </Section>;
}
const Section = styled.section`
  position: relative;
  background-color: ${({
  theme
}) => theme.colorBlack};

  img {
    position: absolute;
    background-size: cover;
    background-position: center;
  }
`;
const Overlay = styled.div`
  position: relative;
  background-color: rgba(0,0,0,0.25);
`;
const Content = styled.div`
  padding: 250px 15px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  align-items: center;

  @media (${({
  theme
}) => theme.underScreenMedium}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding: 150px 15px;
  }

  h2 {
    ${titleLarge}
    color: ${({
  theme
}) => theme.colorWhite};
    margin: 0;

    @media (${({
  theme
}) => theme.underScreenMedium}) {
      font-size: ${({
  theme
}) => theme.fontSizeM};
      line-height: ${({
  theme
}) => theme.fontLineHeightM};
    }
  }
`;