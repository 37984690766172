import styled from 'styled-components';
import i18n from 'src/utils/translate';
import config from 'config/config';
import { ResponsiveImage } from 'src/components/responsiveImage';
import { titleMedium } from 'styles-js/mixins/typography';
export default function FeatureList() {
  return <Features data-sentry-element="Features" data-sentry-component="FeatureList" data-sentry-source-file="featureList.tsx">
      <Feature data-sentry-element="Feature" data-sentry-source-file="featureList.tsx">
        <ResponsiveImage alt={i18n.t('Take control of your health')} height={700} src={`${config.cdn.static}img/home/homepageicons-03.png`} width={700} data-sentry-element="ResponsiveImage" data-sentry-source-file="featureList.tsx" />
        <Title data-sentry-element="Title" data-sentry-source-file="featureList.tsx">{i18n.t('Take control of your health')}</Title>
        <div>{i18n.t('We cover over 300+ conditions and wellbeing interests, so you can treat your health holistically.')}</div>
      </Feature>
      <Feature data-sentry-element="Feature" data-sentry-source-file="featureList.tsx">
        <ResponsiveImage alt={i18n.t('Connect with people like you')} height={700} src={`${config.cdn.static}img/home/homepageicons-01.png`} width={700} data-sentry-element="ResponsiveImage" data-sentry-source-file="featureList.tsx" />
        <Title data-sentry-element="Title" data-sentry-source-file="featureList.tsx">{i18n.t('Connect with people like you')}</Title>
        <div>{i18n.t('People visit our platform everyday to connect with others who are going through similar health challenges.')}</div>
      </Feature>
      <Feature data-sentry-element="Feature" data-sentry-source-file="featureList.tsx">
        <ResponsiveImage alt={i18n.t('Get online support 24/7')} height={700} src={`${config.cdn.static}img/home/homepageicons-02.png`} width={700} data-sentry-element="ResponsiveImage" data-sentry-source-file="featureList.tsx" />
        <Title data-sentry-element="Title" data-sentry-source-file="featureList.tsx">{i18n.t('Get online support 24/7')}</Title>
        <div>{i18n.t(`With access to resources, e-learning and experts you'll learn about the interests that are relevant to your health.`)}</div>
      </Feature>
    </Features>;
}
const Features = styled.div`
  display: flex;
  gap: 20px;

  img {
    width: 50%;
  }

  @media (${({
  theme
}) => theme.underScreenSmall}) {
    flex-direction: column;
    img {
      max-width: 250px;
    }
  }
`;
const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
  flex: 1;
`;
const Title = styled.div`
  ${titleMedium}
`;