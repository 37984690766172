import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Section from 'src/modules/home/visitor/components/section';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section as FFSection } from 'src/constants/footfall';
import Button from 'src/components/buttons/button';
import { titleLarge, titleMedium } from 'styles-js/mixins/typography';
const QUOTES = 5;
export default function UserQuotesSection() {
  const [currentQuote, setCurrentQuote] = useState(0);
  const onBackClick = () => {
    setCurrentQuote((currentQuote - 1 + QUOTES) % QUOTES);
  };
  const onForwardClick = useCallback(() => {
    setCurrentQuote((currentQuote + 1) % QUOTES);
  }, [currentQuote]);
  useEffect(() => {
    const interval = setInterval(onForwardClick, 20000);
    return () => clearInterval(interval);
  }, [onForwardClick]);
  return <StyledSection data-sentry-element="StyledSection" data-sentry-component="UserQuotesSection" data-sentry-source-file="userQuotesSection.tsx">
      <Button icon="open-left" type="none" onClick={onBackClick} data-sentry-element="Button" data-sentry-source-file="userQuotesSection.tsx" />
      <Quotes data-sentry-element="Quotes" data-sentry-source-file="userQuotesSection.tsx">
        <Quote $isHidden={currentQuote !== 0} data-sentry-element="Quote" data-sentry-source-file="userQuotesSection.tsx">
          “{i18n.t(`I have found it so rewarding to talk to people who have cancer like me. Sometimes I feel like no one understands and people on this site are just like me. I don't know how it got started but it's the best thing that has happened to me.`)}”
          <UserSurveyLink data-sentry-element="UserSurveyLink" data-sentry-source-file="userQuotesSection.tsx" />
        </Quote>

        <Quote $isHidden={currentQuote !== 1} data-sentry-element="Quote" data-sentry-source-file="userQuotesSection.tsx">
          “{i18n.t('HealthUnlocked changed my life, I am now more informed on how to better manage my condition, and I know I am not alone.')}”
          <Subtitle data-sentry-element="Subtitle" data-sentry-source-file="userQuotesSection.tsx">{i18n.t('User review, 2019')}</Subtitle>
        </Quote>

        <Quote $isHidden={currentQuote !== 2} data-sentry-element="Quote" data-sentry-source-file="userQuotesSection.tsx">
          “{i18n.t(`There's so much support and guidance on here, you feel as though you have your own coach. I was sceptical, but here I am, 50 years old, overweight and about to run 25 minutes without stopping.`)}”
          <UserSurveyLink data-sentry-element="UserSurveyLink" data-sentry-source-file="userQuotesSection.tsx" />
        </Quote>

        <Quote $isHidden={currentQuote !== 3} data-sentry-element="Quote" data-sentry-source-file="userQuotesSection.tsx">
          “{i18n.t('I understand my symptoms better and have more confidence in myself when consulting my doctor.')}”
          <Subtitle data-sentry-element="Subtitle" data-sentry-source-file="userQuotesSection.tsx">{i18n.t('User review, 2019')}</Subtitle>
        </Quote>

        <Quote $isHidden={currentQuote !== 4} data-sentry-element="Quote" data-sentry-source-file="userQuotesSection.tsx">
          “{i18n.t(`As a carer it's good to be able to relate to people who are in the same situation and have an idea of stages that our loved ones are going through.`)}”
          <Subtitle data-sentry-element="Subtitle" data-sentry-source-file="userQuotesSection.tsx">{i18n.t('User review, 2019')}</Subtitle>
        </Quote>
      </Quotes>
      <Button icon="open-right" type="none" onClick={onForwardClick} data-sentry-element="Button" data-sentry-source-file="userQuotesSection.tsx" />
    </StyledSection>;
}
function UserSurveyLink() {
  return <Subtitle data-sentry-element="Subtitle" data-sentry-component="UserSurveyLink" data-sentry-source-file="userQuotesSection.tsx">
      <a href="https://assets.hu-production.be/campaign/HU_2018_Engagement_Impact_Insights_Report.pdf" rel="noreferrer" target="_blank" onClick={() => {
      trackFootfallEvent(Event.Clicked, {
        clickMetadata: {
          section: FFSection.UserQuoteTestimonial
        },
        clickType: 'visitor-homepage'
      });
    }}>
        {i18n.t('User survey testimonial')}
      </a>
    </Subtitle>;
}
const StyledSection = styled(Section)`
  display: flex;
  align-items: center;

  button {
    padding: 0;
  }

  .icon {
    color: ${({
  theme
}) => theme.colorBlack};
    font-size: ${({
  theme
}) => theme.fontSizeL};

    @media (${({
  theme
}) => theme.underScreenSmall}) {
      font-size: ${({
  theme
}) => theme.fontSizeM};
    }
  }
`;
const Quotes = styled.div`
  padding: 0 50px;
  text-align: center;

  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding: 0 10px;
  }
`;
const Quote = styled.div<{
  $isHidden?: boolean;
}>`
  ${({
  $isHidden
}) => $isHidden && 'display: none;'}
  ${titleLarge}
  font-style: italic;
  color: ${({
  theme
}) => theme.colorBlack};

  @media (${({
  theme
}) => theme.underScreenSmall}) {
    ${titleMedium}
    color: ${({
  theme
}) => theme.colorBlack};
  }
`;
const Subtitle = styled.div`
  font-style: normal;
  margin-top: 20px;
  font-size: ${({
  theme
}) => theme.fontSizeM};
  color: ${({
  theme
}) => theme.colorBlack};
`;