import { unstable_getImgProps as getImgProps } from 'next/image';
import styled from 'styled-components';
import config from 'config/config';
export default function PartnerLogos() {
  const alt = 'Partner Logos';
  const {
    props
  } = getImgProps({
    alt,
    src: `${config.cdn.static}img/home/partner-logos-mobile.png`,
    width: 684,
    height: 319
  });
  const {
    props: {
      srcSet: desktop
    }
  } = getImgProps({
    alt,
    src: `${config.cdn.static}img/home/partner-logos.png`,
    width: 1102,
    height: 319
  });
  return <picture data-sentry-element="picture" data-sentry-component="PartnerLogos" data-sentry-source-file="partnerLogos.tsx">
      <source media="(min-width: 767px)" srcSet={desktop} />
      <Img {...props} data-sentry-element="Img" data-sentry-source-file="partnerLogos.tsx" /> {/* eslint-disable-line react/jsx-props-no-spreading */}
    </picture>;
}
const Img = styled.img`
  width: 100%;
  height: auto;
`;