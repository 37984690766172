import Router from 'next/router';
import { useDispatch } from 'src/store/store';
import { DefaultLayout } from 'src/components/layouts';
import { showLoginModal } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section as FFSection } from 'src/constants/footfall';
import Section from 'src/modules/home/visitor/components/section';
import Carousel from 'src/modules/home/visitor/components/carousel';
import FeatureList from 'src/modules/home/visitor/components/featureList';
import PressList from 'src/modules/home/visitor/components/pressList';
import Join from 'src/modules/home/visitor/components/joinForm';
import DiscoverSection from 'src/modules/home/visitor/components/discoverSection';
import UserQuotesSection from 'src/modules/home/visitor/components/userQuotesSection';
import SearchSection from 'src/modules/home/visitor/components/searchSection';
import Last30DaysSection from 'src/modules/home/visitor/components/last30daysSection';
import { fetchNumbers } from 'src/modules/home/visitor/actions';
import i18n from 'src/utils/translate';
import config from 'config/config';
import { Category, useCookieCategory } from 'src/utils/cookie';
import { PageContext } from 'src/constants/types';
import { getLoggedInRedirectUrl } from 'src/utils/context';
import YouTubeEmbed from 'src/components/youTubeEmbed';
import { Video } from 'src/constants/youTube';
import PartnerLogos from 'src/modules/home/visitor/components/partnerLogos';
import { ResponsiveImage } from 'src/components/responsiveImage';
import Icon from 'src/components/icon';
import JoinSection from 'src/modules/home/visitor/components/joinSection';
export default function IndexPage() {
  const dispatch = useDispatch();
  const [isFunctionalityAccepted] = useCookieCategory(Category.Functionality);
  return <DefaultLayout fullWidth={true} seo={{
    canonical: 'https://healthunlocked.com'
  }} data-sentry-element="DefaultLayout" data-sentry-component="IndexPage" data-sentry-source-file="index.tsx">
      <div className="home">
        <div className="home__background">
          <div className="home__header">
            <div className="home__banner">
              <div className="home__background-content">
                <p className="home__mobile-text">HealthUnlocked</p>
                <h1>{i18n.t(`The world's largest social network for health`)}</h1>
                <p>{i18n.t('Join today. For free, forever.')}</p>
                <Join isSmall={true} data-sentry-element="Join" data-sentry-source-file="index.tsx" />
                <p>
                  {i18n.t('Already a member?')}{' '}
                  <a href="#" onClick={e => {
                  e.preventDefault();
                  dispatch(showLoginModal());
                }}>
                    {i18n.t('Log in')}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <Carousel data-sentry-element="Carousel" data-sentry-source-file="index.tsx" />
        </div>

        <Section data-sentry-element="Section" data-sentry-source-file="index.tsx">
          <PressList data-sentry-element="PressList" data-sentry-source-file="index.tsx" />
        </Section>
        <SearchSection data-sentry-element="SearchSection" data-sentry-source-file="index.tsx" />
        <Section data-sentry-element="Section" data-sentry-source-file="index.tsx">
          <h2>{i18n.t('A holistic approach to healthcare')}</h2>
          <p>
            {i18n.t('Get information, support and tools that have been shown to improve health outcomes.')}{' '}
            <a href="https://about.healthunlocked.com/validation" rel="noreferrer" target="_blank" onClick={() => {
            trackFootfallEvent(Event.Clicked, {
              clickMetadata: {
                section: FFSection.SeeResearch
              },
              clickType: 'visitor-homepage'
            });
          }}>
              {i18n.t('Learn about our research')} <Icon icon="open-right" data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
            </a>
          </p>
          <FeatureList data-sentry-element="FeatureList" data-sentry-source-file="index.tsx" />
        </Section>
        <UserQuotesSection data-sentry-element="UserQuotesSection" data-sentry-source-file="index.tsx" />
        <Section className="home__partners" data-sentry-element="Section" data-sentry-source-file="index.tsx">
          <h2>{i18n.t('Our partners')}</h2>
          <p>
            {i18n.t("Over 600 organizations have used our technology to build health communities. It's the best available and it's free to nonprofits, health advocates, and patient organizations.")}{' '}
            <a href="https://about.healthunlocked.com/start-a-community" rel="noreferrer" target="_blank" onClick={() => {
            trackFootfallEvent(Event.Clicked, {
              clickMetadata: {
                section: FFSection.PartnersLearnMore
              },
              clickType: 'visitor-homepage'
            });
          }}>
              {i18n.t('Start a community')} <Icon icon="open-right" data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
            </a>
          </p>
          <PartnerLogos data-sentry-element="PartnerLogos" data-sentry-source-file="index.tsx" />
        </Section>
        <Section className="home__video" data-sentry-element="Section" data-sentry-source-file="index.tsx">
          {isFunctionalityAccepted ? <YouTubeEmbed id={Video.HealthUnlocked} title="HealthUnlocked Video" /> : <a aria-label="Video HealthUnlocked The social network for health on YouTube" href={`https://www.youtube.com/watch?v=${Video.HealthUnlocked}`} rel="noreferrer" target="_blank">
                <ResponsiveImage alt="Video HealthUnlocked The social network for health on YouTube" height={664} src={`${config.cdn.static}img/home/homepage-video-fallback-img-2.jpg`} width={1187} />
              </a>}
        </Section>
        <DiscoverSection data-sentry-element="DiscoverSection" data-sentry-source-file="index.tsx" />
        <Last30DaysSection data-sentry-element="Last30DaysSection" data-sentry-source-file="index.tsx" />
        <JoinSection data-sentry-element="JoinSection" data-sentry-source-file="index.tsx" />
      </div>
    </DefaultLayout>;
}
IndexPage.getInitialProps = async ({
  asPath,
  res,
  store: {
    dispatch,
    getState
  }
}: PageContext) => {
  if (getState().context.currentUser) {
    // we use asPath because its value includes URL parameters if they are in the URL
    const redirect = getLoggedInRedirectUrl(asPath);
    if (res) {
      res.writeHead(302, {
        Location: redirect
      }).end();
    } else {
      Router.push(redirect);
    }
    return {};
  } else {
    await dispatch(fetchNumbers());
    return {};
  }
};