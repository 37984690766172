import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Section from 'src/modules/home/visitor/components/section';
import config from 'config/config';
import i18n from 'src/utils/translate';
import { getUTCDate } from 'src/utils/date';
import { useSelector } from 'src/store/store';
import { ResponsiveImage } from 'src/components/responsiveImage';
import { titleLarge, titleXlarge } from 'styles-js/mixins/typography';
export default function Last30DaysSection() {
  const {
    initialPeopleCount,
    currentPeopleCount,
    initialInteractionCount,
    currentInteractionCount,
    peoplePerSecond,
    interactionsPerSecond
  } = useSelector(({
    pages
  }) => pages.home.numbers);
  const [peopleCount, setPeopleCount] = useState(currentPeopleCount);
  const [interactionCount, setInteractionCount] = useState(currentInteractionCount);
  useEffect(() => {
    const interval = setInterval(() => {
      if (Math.random() < 0.25) setPeopleCount(calculateCurrentNumber(initialPeopleCount, peoplePerSecond));
      if (Math.random() < 0.25) setInteractionCount(calculateCurrentNumber(initialInteractionCount, interactionsPerSecond));
    }, 1000);
    return () => clearInterval(interval);
  }, [initialPeopleCount, initialInteractionCount, interactionsPerSecond, peoplePerSecond]);
  return <StyledSection data-sentry-element="StyledSection" data-sentry-component="Last30DaysSection" data-sentry-source-file="last30daysSection.tsx">
      <div>
        <StyledResponsiveImage alt="HealthUnlocked" height={19} sizes="(max-width: 767px) 252px, 315px" src={`${config.cdn.logos}healthunlocked.svg`} width={189} data-sentry-element="StyledResponsiveImage" data-sentry-source-file="last30daysSection.tsx" />
        <Description data-sentry-element="Description" data-sentry-source-file="last30daysSection.tsx">{i18n.t('in the last 30 days')}</Description>
      </div>
      <Stats data-sentry-element="Stats" data-sentry-source-file="last30daysSection.tsx">
        <Number data-sentry-element="Number" data-sentry-source-file="last30daysSection.tsx">{peopleCount}</Number>
        <SubTitle data-sentry-element="SubTitle" data-sentry-source-file="last30daysSection.tsx">{i18n.t('people')}</SubTitle>
      </Stats>
      <Stats data-sentry-element="Stats" data-sentry-source-file="last30daysSection.tsx">
        <Number data-sentry-element="Number" data-sentry-source-file="last30daysSection.tsx">{interactionCount}</Number>
        <SubTitle data-sentry-element="SubTitle" data-sentry-source-file="last30daysSection.tsx">{i18n.t('interactions')}</SubTitle>
      </Stats>
    </StyledSection>;
}
export function calculateCurrentNumber(initialNumber: number, ratePerSecond: number): string {
  const today = getUTCDate();
  const hoursInSeconds = today.getHours() * 3600;
  const minutesInSeconds = today.getMinutes() * 60;
  const secondsInDay = hoursInSeconds + minutesInSeconds + today.getSeconds();
  const currentNumber = initialNumber + secondsInDay * ratePerSecond;
  return Math.floor(currentNumber).toLocaleString();
}
const StyledResponsiveImage = styled(ResponsiveImage)`
  margin-bottom: 15px;
`;
const StyledSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (${({
  theme
}) => theme.underScreenMedium}) {
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;
const Description = styled.div`
  ${titleLarge}
  min-width: 80%;
`;
const Stats = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
const Number = styled.div`
  ${titleXlarge}
  font-size: 70px;

  @media (${({
  theme
}) => theme.underScreenMedium}) {
    font-size: ${({
  theme
}) => theme.fontSizeXl};
    line-height: ${({
  theme
}) => theme.fontLineHeightXl};
  }
`;
const SubTitle = styled.div`
  ${titleLarge}
  margin-top: 15px;

  @media (${({
  theme
}) => theme.underScreenMedium}) {
    margin-top: 5px;
  }
`;